/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const BlogContextProvider = require("./src/components/blog-context").default
const Layout = require("./src/components/layout").default

exports.wrapRootElement = ({ element }) => {
  return <BlogContextProvider>{element}</BlogContextProvider>
}

exports.wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}
