import React from "react"
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles"
import { blueGrey, grey } from "@material-ui/core/colors"
import { Container, CssBaseline } from "@material-ui/core"
import Navbar from "./navbar"

interface LayoutProps {
  children: React.ReactNode
}

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      paper: blueGrey[800],
      default: grey[700],
    },
    secondary: {
      main: "#880e4f",
    },
  },
  typography: {
    fontFamily: '"Fira Code","Helvetica", "Arial", sans-serif',
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2.5),
    },
  })
)
export default function Layout(props: LayoutProps) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Navbar />
      <Container maxWidth="lg" className={classes.container}>
        {props.children}
      </Container>
    </ThemeProvider>
  )
}
