import React, { useContext, useEffect, useState } from "react"
import {
  AppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import { Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { BlogContext } from "./blog-context"

import { Auth } from "aws-amplify"
import awsconfig from "../aws-exports"

Auth.configure(awsconfig)

const useStyles = makeStyles(() =>
  createStyles({
    appBarTitle: {
      fontFamily: "Rock Salt",
      cursor: "pointer",
    },
    navBarSpacer: {
      flexGrow: 1,
    },
  })
)

export default function Navbar() {
  const { pathname } = useLocation()

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [menuItems, setMenuItems] = useState<{ [key: string]: string }>({})

  const { state } = useContext(BlogContext)

  const classes = useStyles()

  useEffect(() => {
    if (/^\/admin/.test(pathname)) {
      setMenuItems({
        Home: "/",
        Admin: "/admin",
      })
    } else {
      setMenuItems({
        "Are you me?": "/admin",
      })
    }
  }, [pathname])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleHomeClick = () => {
    navigate("/")
  }

  const handleSignOut = async () => {
    await Auth.signOut()
    setMenuAnchor(null)
    navigate("/")
  }

  const menu = (
    <Menu
      open={!!menuAnchor}
      anchorEl={menuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleMenuClose}
    >
      {Object.entries(menuItems).map(([label, path], idx) => {
        return (
          <MenuItem
            key={idx}
            onClick={handleMenuClose}
            component={Link}
            to={path}
          >
            {label}
          </MenuItem>
        )
      })}
      {state.loggedIn && <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>}
    </Menu>
  )

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography
          variant="h4"
          className={classes.appBarTitle}
          onClick={handleHomeClick}
        >
          really x loco
        </Typography>
        <div className={classes.navBarSpacer} />
        <IconButton onClick={handleMenuOpen} aria-label="admin menu">
          <AccountCircleIcon />
        </IconButton>
        {menu}
      </Toolbar>
    </AppBar>
  )
}
