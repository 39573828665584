/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:24b5ea64-5087-4ee5-a698-476e56fc5cee",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_RbkvorBP8",
    "aws_user_pools_web_client_id": "23adgjjs47smdanvf28rjp2lmv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gexym24buvbh7htmehnyigc3du.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ngn7ndgtbbc5lie7p324nzhyky",
    "aws_user_files_s3_bucket": "reallyloco-blog828f32592ee64048a920d819f97eea36master-master",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
