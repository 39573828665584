import React, { createContext, Dispatch, useEffect, useReducer } from "react"
import { Amplify, Auth, Hub } from "aws-amplify"
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

export enum BlogContextActions {
  UPDATE_BLOG_LIST_SHOW,
  SET_LOGGED_IN,
}

interface BlogState {
  blogListShow: number
  loggedIn: boolean
}

const initialState: BlogState = {
  blogListShow: 10,
  loggedIn: false,
}

interface Action {
  type: BlogContextActions
  payload: any
}

const reducer = (state: BlogState, action: Action): BlogState => {
  switch (action.type) {
    case BlogContextActions.UPDATE_BLOG_LIST_SHOW:
      return {
        ...state,
        blogListShow: action.payload.count,
      }
    case BlogContextActions.SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
      }
  }

  return state
}

export const BlogContext = createContext<{
  state: BlogState
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

interface BlogContextProviderProps {
  children: any
}

export default function BlogContextProvider(props: BlogContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        dispatch({
          type: BlogContextActions.SET_LOGGED_IN,
          payload: {
            loggedIn: true,
          },
        })
        break
      default:
        dispatch({
          type: BlogContextActions.SET_LOGGED_IN,
          payload: {
            loggedIn: false,
          },
        })
    }
  })

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          dispatch({
            type: BlogContextActions.SET_LOGGED_IN,
            payload: {
              loggedIn: true,
            },
          })
        }
      } catch (err) {}
    }

    if (window) {
      checkUser()
    }
  }, [dispatch])

  return (
    <BlogContext.Provider value={{ state, dispatch }}>
      {props.children}
    </BlogContext.Provider>
  )
}
